import * as React from 'react';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { graphql } from 'gatsby';
import { UseStyles } from '../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { GQLBlogListingQueryQuery } from '../lib/types/graphql/__generated__/gatsby.gql';
import { Typography } from '../components/Typography';
import { Page } from '../components/app/Page';
import { SEO } from '../components/app/SEO';
import { SocialLink } from '../components/SocialLink';
import { FluidObject } from 'gatsby-image';
import { BlogPostCardGrid } from '../components/BlogPostCardGrid';
import { TBlogPostCardP } from '../components/BlogPostCard';

type MdrEdge = GQLBlogListingQueryQuery['allMarkdownRemark']['edges'][0];

type ClassKey = 'cardContainer' | 'cardContainerGrid' | 'cardContainerGridItem' | 'main';

const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: { padding: theme.spacing(4) },
    cardContainer: { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: theme.spacing(2) },
    cardContainerGrid: {
        flexGrow: 1,
        maxWidth: `45em`,
        padding: theme.spacing(2)
    },
    cardContainerGridItem: { width: '100%', },
}));

export type TBlogListingTemplateP = {
    data: GQLBlogListingQueryQuery;
};

function BlogListing(props: TReactFCP<TBlogListingTemplateP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { sitePage, blogHero, allMarkdownRemark }: GQLBlogListingQueryQuery = props.data;
    const posts: TBlogPostCardP[] = allMarkdownRemark!.edges.map(({ node: post }: MdrEdge) => ({
        tags: post.frontmatter!.tags as string[] || [],
        slug: post.fields!.slug!,
        title: post.frontmatter!.title!,
        spoiler: post.frontmatter!.spoiler!,
        timeToRead: post!.timeToRead!,
        publishDate: post.frontmatter!.publishDate!,
        fluidImage: post.frontmatter!.hero!.childImageSharp!.fluid! as FluidObject
    }));
    return (
        <main className={styles.main}>
            <SEO
                slug={sitePage!.path!}
                title='blog'
                seoTitle={`Stories of greatish importance`}
                description={`So I was writin' an article the other daaaay...`}
                image={{
                    src: blogHero!.childImageSharp!.original!.src!,
                    width: blogHero!.childImageSharp!.original!.width!,
                    height: blogHero!.childImageSharp!.original!.height!,
                }}
            />
            <Typography display='block' variant='h1' align='center'>Articles</Typography>
            <Typography display='block' variant='subtitle2' align='center' color='lighter' paragraph>
                {`Deep thoughts here, shenanigans on `}
                <SocialLink variant='twitter' enableText disableIcon enableUnderline bold />
            </Typography>
            <BlogPostCardGrid posts={posts} />
        </main>
    );
}

export default (props: TReactFCP<TBlogListingTemplateP>): TReactFCR => <Page component={BlogListing} cprops={props} />;

export const query = graphql`
    query BlogListingQuery {
        sitePage(internalComponentName: {eq: "ComponentBlog"}) {
            path
        }
        blogHero: file(relativePath: {eq: "images/blog-hero.jpg"}) {
            childImageSharp {
                original {
                    src
                    width
                    height
                }
            }
        }
        allMarkdownRemark(
            sort: {fields: [frontmatter___publishDate], order: DESC }, filter: {fields: {slug: {glob: "/blog/*"}}}
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        publishDate(formatString: "MMM DD, YYYY")
                        spoiler
                        tags
                        hero {
                            childImageSharp {
                                # Max width of preview cards is ~600
                                fluid(maxWidth: 600) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    timeToRead
                }
            }
        }
    }
`;
