import * as React from 'react';
import GatsbyImage, { GatsbyImageProps } from 'gatsby-image';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { Card, CardActionArea, Theme, CardContent, makeStyles } from '@material-ui/core';
import { Link } from './Link';
import { UseStyles } from '../lib/types/mui';
import { Typography } from './Typography';
import { GlassesIcon } from './FAIcons';

type ClassKey = 'cardActionArea' | 'cardMedia' | 'cardContent' | 'date' | 'timeToRead' | 'timeToReadIcon';

const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    cardActionArea: {
        [theme.breakpoints.up('sm')]: { display: 'flex', alignItems: 'stretch' }
    },
    cardMedia: {
        [theme.breakpoints.up('sm')]: { flex: '0 1 auto', minWidth: '150px', width: '150px' }
    },
    cardContent: { flexGrow: 1, padding: theme.spacing(4, 5) },
    timeToRead: {
        display: 'inline-flex',
        alignItems: 'center',
        marginTop: theme.spacing(2)
    },
    timeToReadIcon: {
        ...theme.typography.iconSizing(2),
        color: theme.palette.custom.text.color.lighter,
        marginRight: theme.spacing(2)
    },
    date: {
        padding: theme.spacing(1),
        flexGrow: 0,
        color: theme.palette.custom.card.labelArea.contrastText.lighter,
        backgroundColor: theme.palette.custom.card.labelArea.color,
        [theme.breakpoints.up('sm')]: { writingMode: 'vertical-lr' }
    },
}));

export type TBlogPostCardP = {
    tags?: string[];
    slug: string;
    title: string;
    spoiler: string;
    timeToRead: number;
    publishDate: string;
    fluidImage: GatsbyImageProps['fluid'];
};

export function BlogPostCard(props: TReactFCP<TBlogPostCardP>): TReactFCR {
    const { slug, title, spoiler, timeToRead, publishDate, fluidImage, tags = [] }: TBlogPostCardP = props;
    const styles: UseStyles<ClassKey> = useStyles();
    return (
        <Card>
            <CardActionArea component={Link} variant='internal' to={slug} disableColor disableUnderline>
                <div className={styles.cardActionArea}>
                    <GatsbyImage className={styles.cardMedia} fluid={fluidImage} />
                    <CardContent className={styles.cardContent}>
                        <Typography variant='overline' color='light'>{tags.join(' · ')}</Typography>
                        <Typography variant='h4'>{title}</Typography>
                        <Typography variant='body1'>{spoiler}</Typography>
                        <Typography className={styles.timeToRead} variant='overline' align='right'>
                            <GlassesIcon className={styles.timeToReadIcon} />
                            {`${timeToRead} min${timeToRead > 1 ? 's' : ''}`}
                        </Typography>
                    </CardContent>
                    <div className={styles.date}>
                        <Typography component='div' variant='caption' align='center' noWrap>
                            {publishDate}
                        </Typography>
                    </div>
                </div>
            </CardActionArea>
        </Card>
    );
}
