import * as React from 'react';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { Grid, Theme, makeStyles } from '@material-ui/core';
import { UseStyles } from '../lib/types/mui';
import { BlogPostCard, TBlogPostCardP } from './BlogPostCard';

type ClassKey = 'cardContainer' | 'cardContainerGrid' | 'cardContainerGridItem';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    cardContainerGrid: {
        flexGrow: 1,
        maxWidth: `42em`,
        padding: theme.spacing(2)
    },
    cardContainerGridItem: { width: '100%', },
}));

export type TBlogPostCardGridP = {
    posts: TBlogPostCardP[];
};

export function BlogPostCardGrid(props: TReactFCP<TBlogPostCardGridP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    return (
        <div className={styles.cardContainer}>
            <Grid container spacing={4} className={styles.cardContainerGrid}>
                {props.posts.map((c: TBlogPostCardP) => (
                    <Grid item key={c.slug} className={styles.cardContainerGridItem}>
                        <BlogPostCard {...c} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
